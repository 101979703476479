<template>
  <div>
    <AppButton
      height="27px"
      width="60px"
      rounded="none"
      :variant="isPayable ? 'blue' : 'gray'"
      text="Pay"
      @click="openModal()"
    />
    <t-modal
      :name="`available-fund-transfer-${id}`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="!isLoading"
      :clickToClose="!isLoading"
      ref="popup"
    >
      <div>
        <section class="px-4 text-2xl font-extrabold text-center mt-8">
          Please enter payable amount
        </section>
        <section class="px-4 ">
          <AppInput
            v-model="form.amount"
            rules="required"
            label="Amount"
            type="number"
            step="0.01"
            name="Amount"
          />
        </section>

        <section
          class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
        >
          <AppButton variant="secondary" :class="`w-1/2`" @click="closeModal()">
            Cancel
          </AppButton>

          <AppButton
            :class="`w-1/2`"
            :isLoading="isLoading"
            @click="transferMoney()"
          >
            Transfer
          </AppButton>
        </section>
      </div>
    </t-modal>
  </div>
</template>

<script>
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
export default {
  name: 'AvailableFundsTransfer',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,

    form: {
      amount: null,
      buyer: null,
    },
  }),
  computed: {
    isPayable() {
      if (this.data.available_funds > 0) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    data: {
      deep: false,
      immediate: true,
      handler(updateData) {
        console.log('updateData-d', updateData)
        if (updateData) {
          this.form.amount = updateData.available_funds
          this.form.buyer = updateData.id
          console.log('updateData-f', this.form)
        }
      },
    },
  },
  methods: {
    openModal() {
      if (this.data.available_funds <= 0) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Info',
            text: "Don't have enough funds to transfer",
          },
          5000
        )
        return
      }
      this.$modal.show(`available-fund-transfer-${this.id}`)
    },
    closeModal() {
      this.form.amount = this.data.available_funds
      this.$modal.hide(`available-fund-transfer-${this.id}`)
    },

    async transferMoney() {
      this.isLoading = true
      const url = MarketplaceConfig.api.transferMoneyOfFunds()
      const formDataProxy = { ...this.form }

      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }

      await this.$http
        .post(url, data)
        .then(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Successful',
              text: 'Transfer successfully',
            },
            3000
          )
          this.closeModal()
          this.$emit('force-sync')
        })
        .catch((err) => {
          console.log('transfer-money-err', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error occurred!',
              text: 'Failed to update status',
            },
            7000
          )
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped></style>
